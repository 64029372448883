import React from "react";
import SignForm from "../SignForm/SignForm";
import Details from "../Details/Details";
import { styled } from "@mui/system";
const ContainerBorder = styled("div")({
  border: "1px solid gray",
  display: "flex",
  height: "100vh",
});

const SignInContainer = styled("div")({
  width: "50%",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
});

const DetailsContainer = styled("div")({
  width: "50%",
  display: "flex",
  backgroundColor: "#0022D2",
  color: "white",
});
const Home = () => {
  return (
    <>
      <section>
        <ContainerBorder>
          <SignInContainer>
            <SignForm />
          </SignInContainer>
          <DetailsContainer>
            <Details />
          </DetailsContainer>
        </ContainerBorder>
      </section>
    </>
  );
};

export default Home;
