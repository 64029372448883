import React from "react";

const Inputs = ({
  type,
  id,
  placholder,
  cstyle,
  labeltext,
  cclass,
  clclass,
}) => {
  return (
    <div className="mb-2">
      <label htmlFor={id} className={clclass} style={cstyle}>
        {labeltext}
        <input
          type={type}
          id={id}
          placeholder={placholder}
          className={cclass}
        />
      </label>
    </div>
  );
};

export default Inputs;
