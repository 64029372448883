import React from "react";
import Inputs from "../Shared/Inputs";
import google from "../../assets/img/Google.png";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

const FormDiv = styled("div")({
  width: "35rem",
});
const ForminDiv = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
});

const W100Div = styled("div")({
  width: "100%",
});

const W50Div = styled("div")({
  width: "50%",
});

const SignInBtn = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  borderColor: "#0063cc",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const SignupBtn = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  fontWeight: "500",
  backgroundColor: "#5F849C",
  color: "white",
  width: "100%",
  padding: "10px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#0069d9",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});
const SignForm = () => {
  return (
    <FormDiv>
      <form>
        <ForminDiv>
          <W100Div>
            <div
              style={{
                fontWeight: "500",
                fontSize: "24px",
              }}
            >
              Sign Up
            </div>
            <div
              className="text-muted mb-5"
              style={{
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Enter details to create your account
            </div>
          </W100Div>
          <W100Div>
            <Inputs
              clclass="form-label fw-bold"
              cclass="form-control p-2"
              type="text"
              placholder="Enter your Name"
              id="name "
              labeltext="Your Name"
              cstyle={{
                width: "100%",
              }}
            />
          </W100Div>
          <W100Div>
            <Inputs
              clclass="form-label fw-bold"
              cclass="form-control p-2"
              type="email"
              placholder="Enter your Email"
              id="email "
              labeltext="E-mail or phone numbers"
              cstyle={{
                width: "100%",
              }}
            />
          </W100Div>
          <W100Div className="d-flex">
            <W50Div
              style={{
                marginRight: "5px",
              }}
            >
              <Inputs
                clclass="form-label fw-bold"
                cclass="form-control p-2"
                type="password"
                placholder="Enter your Password"
                id="password"
                labeltext="Password"
                cstyle={{
                  width: "100%",
                  marginRight: "5px",
                }}
              />
            </W50Div>
            <W50Div
              style={{
                marginLeft: "5px",
              }}
            >
              <Inputs
                clclass="form-label fw-bold"
                cclass="form-control p-2"
                type="password"
                placholder="Enter your Password"
                id="cpassword"
                labeltext="Confirm Password"
                cstyle={{
                  width: "100%",
                }}
              />
            </W50Div>
          </W100Div>

          <W100Div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <SignInBtn
              className="btn border w-100"
              style={{
                fontWeight: "500",
              }}
            >
              <img src={google} alt="google" className="me-2" width={"20px"} />{" "}
              Sign in with Google
            </SignInBtn>
          </W100Div>
          <W100Div>
            <SignupBtn>Sign up</SignupBtn>
          </W100Div>
          <W100Div>
            <div className="text-center">
              Already have an account?{" "}
              <Link className="text-decoration-none">Sign in</Link>
            </div>
          </W100Div>
        </ForminDiv>
      </form>
    </FormDiv>
  );
};

export default SignForm;
