import React from "react";
import logo from "../../assets/img/logo.png";
import exmimg from "../../assets/img/exmimg.png";
import "./Details.css";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";
const DetailDiv = styled("div")({
  display: "flex",
  flex: 1,
  height: "100%",
  flexWrap: "wrap",
  position: "relative",
});
const W100Div = styled("div")({
  width: "100%",
});

const Details = () => {
  return (
    <>
      <DetailDiv>
        <div className="whitefade1"></div>
        <div className="whitefade2"></div>
        <div className="whitefade3"></div>
        <W100Div
          style={{
            textAlign: "right",
            paddingRight: "2rem",
            width: "100%",
          }}
        >
          <img src={logo} alt="logo" width={"220px"} className="mt-5 " />
          <Typography
            sx={{
              marginTop: "16rem",
              fontWeight: "500",
              fontSize: "40px",
              textAlign: "end",
              marginLeft: "auto",
              paddingRight: "1rem",
              marginBottom: "1rem",
            }}
          >
            Automate your message service
          </Typography>
          <Typography
            sx={{
              width: "34rem",
              fontWeight: "300",
              fontSize: "16px",
              textAlign: "end",
              marginLeft: "auto",
              paddingRight: "1rem",
              marginBottom: "1rem",
            }}
          >
            Make your tasks easy with Loopmee by an automated integration along
            with your contacts
          </Typography>
        </W100Div>
        <div
          style={{
            position: "absolute",
            transform: "translate(359px, 524px)",
          }}
        >
          <img
            src={exmimg}
            alt=""
            style={{
              width: "600px",
              height: "450px",
            }}
          />
        </div>
      </DetailDiv>
    </>
  );
};

export default Details;
